<template>
  <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <div class="card">
      <div class="card-body">
        <div>
          <h3>
            อัพโหลดแบนเนอร์
          </h3>
        </div>
        <div class="row justify-content-center">
          <div class="col-md-3">
            <div>
              <div class="form-group">
                <b-form-group
                  label="เพิ่มรูปภาพ แบนเนอร์"
                  label-for="uploadImage"
                >
                  <div>
                    <b-form-file
                      id="uploadImage"
                      v-model="file"
                      placeholder="Choose a file or drop it here..."
                      drop-placeholder="Drop file here..."
                      @input="uploadImg(file)"
                    />
                  </div>
                </b-form-group>
              </div>

              <div class="text-center">
                <b-button
                  v-b-modal.modal-1
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="gradient-primary"
                  class="mr-1"
                  @click="Submit"
                >
                  บันทึกข้อมูล
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-card
      no-body
    >
      <b-table
        small
        striped
        hover
        responsive
        show-empty
        class="position-relative items-center"
        :per-page="perPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
      >
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + (data.index + 1) }}
        </template>

        <template #cell(imgshow)="data">
          <img
            :src="data.item.img"
            alt="img"
            class="img-fluid"
            style="width: auto; height: 100px"
          >
        </template>
        <template #cell(actions)="data">
          <b-form-checkbox
            v-model="data.item.status"
            class="custom-control-success"
            name="check-button"
            switch
            @change="ChangeStatus(data.item._id,data.item.status)"
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
        </template>
        <template #cell(del)="data">
          <feather-icon
            :id="`invoice-row-${data.item._id}-preview-icon`"
            icon="TrashIcon"
            size="16"
            class="mx-1"
            @click="deleteData(data.item._id)"
          />
        </template>

      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="8"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
            @change="getHistory()"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            @input="getHistory()"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BFormCheckbox,
  VBTooltip, BCard, BTable, BFormGroup, BFormSelect, BPagination, BCardBody, VBToggle, BOverlay, BIconController, BFormFile, BButton,
  // BFormRadio,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BFormFile,
    BCard,
    BTable,
    // BAvatar,
    BFormGroup,
    BFormSelect,
    BPagination,
    BButton,
    BCardBody,
    BOverlay,
    BIconController,
    BFormCheckbox,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      show: false,
      title: '',
      detail: '',
      img: '',
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      usersList: [],
      datasidebar: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'index', label: 'no.', thStyle: { width: '5%' } },
        // { key: 'detail', label: 'ข้อมูลข่าว', thStyle: { width: '45%' } },
        { key: 'imgshow', label: 'รูป', thStyle: { width: '75%' } },
        { key: 'actions', label: 'ปิด - เปิด', thStyle: { width: '5%' } },
        { key: 'del', label: 'ลบรูปภาพ', thStyle: { width: '5%' } },
      ],
      /* eslint-disable global-require */
      items: [],
      addNewDataSidebar: false,
      UserData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    this.getHistory()
  },
  methods: {
    ChangeStatus(id, status) {
      // console.log(status)
      // if (status) {
      //   // eslint-disable-next-line block-scoped-var, vars-on-top, no-var
      //   var types = 1
      // } else {
      //   // eslint-disable-next-line vars-on-top, no-var, no-redeclare, block-scoped-var
      //   var types = 0
      // }
      const obj = {
        id,
        // eslint-disable-next-line block-scoped-var
        status,
      }
      // console.log(params)
      this.$http
        .post('/banner/switch', obj)
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.getHistory()
        })
        .catch(error => console.log(error))
    },
    uploadImg(file) {
      // แปลงไฟล์รูปเป็น base64
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        this.img = reader.result
        // บีบอัดรูปให้เล็กลง
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        const image = new Image()
        image.src = this.img
        image.onload = () => {
          canvas.width = image.width
          canvas.height = image.height
          ctx.drawImage(image, 0, 0, image.width, image.height)
          const dataUrl = canvas.toDataURL('image/jpeg')
          this.img = dataUrl
        }
      }
    },
    Submit() {
      const obj = {
        img: this.img,
      }
      this.$http
        .post('banner/upload',
          obj)
        // eslint-disable-next-line no-unused-vars
        .then(ress => {
          this.Success('อัพโหลดรูปสำเร็จ')
          this.getHistory()
          this.img = ''
        })
    },
    deleteData(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.$http
            .get(`/banner/delete/${id}`)
            .then(response => {
              this.getHistory()
              if (response.data) {
                this.$swal({
                  icon: 'success',
                  title: 'Deleted!',
                  text: 'Your file has been deleted.',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
            })
            .catch(error => console.log(error))
        } else {
          this.$swal('Cancelled', 'not been deleted !', 'error')
        }
      })
    },
    getHistory() {
      const obj = {
        page: this.currentPage,
        perPage: this.perPage,
      }
      this.$http
        .post('banner/index', obj)
        .then(res => {
          this.onFiltered(res.data)
        })
    },
    Commas(x) {
      if (!x) {
        return 0
      } if (x % 1 !== 0) {
        return Number(x).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    onFiltered(filteredItems) {
      this.items = filteredItems.data
      this.totalRows = filteredItems.total
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>
<style scoped>
.favrt {
  margin: 0 10px;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background: #fff;
  border: 3px solid #efceff8c;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
}

.favrt-add {
  margin: 0 10px;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background: #f8f0fc;
  border: 3px dotted #e4a2ec8c;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
}
.favrt-add i {
  color: #9191918e;
}

.txtn {
  position: relative;
}
.txtn .closed-x {
  position: absolute;
  top: -5px;
  right: 5px;
  background: #fc0202;
  color: #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
  .bank-icon {
    width: 35px;
    height: 35px;
    padding: 5px;
    border-radius: 50%;
  }
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.form-item-section {
background-color:$product-details-bg;
}

</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
